import { ExportTemplate, Plan } from '@helpers/firebase_helper';
import { getDownloadURL, ref } from 'firebase/storage';
import { DropdownItem } from 'reactstrap';
import { getFirebaseBackend } from '../../../../../firebase';
import { downloadProvisionsExport } from '../../../../../helpers/export_provisions';

interface TemplateOptionsProps {
  title: string;
  loading: boolean;
  templates: ExportTemplate[];
  plan: Plan;
}

const TemplateOptions = ({ title, loading, templates, plan }: TemplateOptionsProps) => {
  const firebaseHelper = getFirebaseBackend();
  return loading ? (
    <DropdownItem disabled>(loading)</DropdownItem>
  ) : (
    <>
      <DropdownItem header>{title}</DropdownItem>
      {templates.length > 0 ? (
        templates.map((template: ExportTemplate) => (
          <DropdownItem
            key={template.id}
            onClick={async () => {
              const url = await getDownloadURL(ref(firebaseHelper.storage, template.fileUri));
              await downloadProvisionsExport(plan, url);
            }}
          >
            {template.templateName}
          </DropdownItem>
        ))
      ) : (
        <DropdownItem disabled>No templates found</DropdownItem>
      )}
    </>
  );
};

export default TemplateOptions;
