import { AppDispatch } from 'index';

export const setModals = (dispatch: AppDispatch, payload: Object) => {
  dispatch({
    type: 'templates/setModals',
    payload,
  });
};

export const setLayout = (dispatch: AppDispatch, payload: Object) => {
  dispatch({
    type: 'templates/setLayout',
    payload,
  });
};
