import { ExportTemplate, Plan } from '@helpers/firebase_helper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { fetchExportTemplates } from 'slices/template/thunk';
import {
  downloadProvisionsExport,
  downloadProvisionsExportWord,
} from '../../../../helpers/export_provisions';
import TemplateOptions from './components/TemplateOptions';
import { setLayout, setModals } from './dispatcher';
import CreateExportTemplateModal from './modals/CreateExportTemplateModal';
import EditTemplatesModal from './modals/EditTemplateListModal';

interface ExportPlanButtonProps {
  plan: Plan;
}

const ExportPlanButton = ({ plan }: ExportPlanButtonProps) => {
  const dispatch = useDispatch<any>();

  const [refreshTemplates, setRefreshTemplates] = useState(true);

  const { templates, loading, isCreateTemplateOpen, isDropdownOpen } = useSelector(
    (state: any) => ({
      templates: state.Templates.templates as ExportTemplate[],
      loading: state.Templates.loading as boolean,
      isCreateTemplateOpen: state.Templates.modals.createTemplate as boolean,
      isDropdownOpen: state.Templates.layout.templateListDropdown as boolean,
    }),
  );

  const showCreateModalState = (state: boolean) => {
    if (!state) {
      setRefreshTemplates(true);
    }
    setModals(dispatch, { createTemplate: state });
  };

  useEffect(() => {
    if (refreshTemplates) {
      dispatch(fetchExportTemplates());
      setRefreshTemplates(false);
    }
  }, [refreshTemplates]);

  return (
    <>
      <ButtonGroup>
        <Dropdown
          isOpen={isDropdownOpen}
          toggle={() => setLayout(dispatch, { templateListDropdown: !isDropdownOpen })}
        >
          <DropdownToggle color='primary' className='dropdown-toggle-split'>
            <span className='mx-3'>Export Plan</span>
            <i className='mdi mdi-chevron-down' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => downloadProvisionsExport(plan)}>
              PlanPort Template (Microsoft Excel)
            </DropdownItem>
            <DropdownItem onClick={() => downloadProvisionsExportWord(plan)}>
              PlanPort Template (Microsoft Word)
            </DropdownItem>
            <DropdownItem divider />
            <TemplateOptions
              title='Organization Templates'
              loading={loading}
              templates={templates.filter((template: ExportTemplate) => !!template.organizationId)}
              plan={plan}
            />
            <DropdownItem divider />
            <TemplateOptions
              title='User Templates'
              loading={loading}
              templates={templates.filter((template: ExportTemplate) => !template.organizationId)}
              plan={plan}
            />
            <DropdownItem divider />
            <DropdownItem header>Actions</DropdownItem>
            <DropdownItem onClick={() => showCreateModalState(true)}>
              Upload new template
            </DropdownItem>
            <DropdownItem
              onClick={() => setModals(dispatch, { editTemplates: true })}
              disabled={templates.length == 0}
            >
              Edit templates
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ButtonGroup>
      <CreateExportTemplateModal isOpen={isCreateTemplateOpen} setIsOpen={showCreateModalState} />
      <EditTemplatesModal />
    </>
  );
};

export default ExportPlanButton;
