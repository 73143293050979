import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import { useDispatch, useSelector } from 'react-redux';

import withRouter from 'Components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from 'Components/Common/Breadcrumb';

import OtpInputCode from 'Components/Common/OtpInputCode';
import { TotpSecret } from 'firebase/auth';
import QRCode from 'react-qr-code';
import { createSelector } from 'reselect';
import { editProfile, resetProfileFlag } from 'slices/thunk';
import { getFirebaseBackend } from '../../firebase';

const UserProfile = () => {
  //meta title
  document.title = 'Profile | Plan Data AI';

  const dispatch = useDispatch<any>();

  const [email, setEmail] = useState('admin@gmail.com');
  const [name, setName] = useState(null);
  const [idx, setIdx] = useState(1);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [totpSecret, setTotpSecret] = useState<TotpSecret>();
  const [totpQRCode, setTotpQRCode] = useState('');
  const [otpValidation, setOtpValidation] = useState('');
  const [otpName, setOtpName] = useState('');
  const [otpEnrollmentTime, setOtpEnrollmentTime] = useState('');

  const selectProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      user: profile.user,
      error: profile.error,
      success: profile.success,
    }),
  );

  const { error, success, user } = useSelector(selectProperties);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const storedUser: any = localStorage.getItem('authUser');
      const obj = JSON.parse(storedUser);
      setName(user.username || obj.displayName);
      setEmail(obj.email);
      setIdx(obj.uid);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);

      setTimeout(() => {
        const fireBaseBackend = getFirebaseBackend();
        const mfaConfig = fireBaseBackend.getUserMFAConfig();
        if (mfaConfig) {
          setMfaEnabled(true);
          setOtpName(mfaConfig.displayName!);
          setOtpEnrollmentTime(mfaConfig.enrollmentTime);
        } else {
          setMfaEnabled(false);
          setOtpName('');
          setOtpEnrollmentTime('');
        }
      }, 1000);
    }
  }, [dispatch, user]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: name,
      idx: idx || 1,
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  useEffect(() => {
    const loadTotpSecret = async () => {
      const fireBaseBackend = getFirebaseBackend();

      // load setup configuration.
      if (mfaEnabled && otpName === '') {
        const secret = await fireBaseBackend.getTOTPSecretForEnrollment(dispatch);
        if (secret) {
          setTotpQRCode(secret!.qrCode);
          setTotpSecret(secret!.totpSecret);
        }
      }

      if (!mfaEnabled && otpName != '') {
        await fireBaseBackend.disableUserMfa(dispatch);
      }
    };

    loadTotpSecret();
  }, [mfaEnabled]);

  useEffect(() => {
    const enrollUser = async () => {
      if (otpValidation.length == 6) {
        const fireBaseBackend = getFirebaseBackend();
        await fireBaseBackend.enrollUserOnMfa(totpSecret, otpValidation);

        const mfaConfig = fireBaseBackend.getUserMFAConfig();
        if (mfaConfig) {
          setMfaEnabled(true);
          setOtpName(mfaConfig.displayName!);
          setOtpEnrollmentTime(mfaConfig.enrollmentTime);
        }
      }
    };
    enrollUser();
  }, [otpValidation]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title='PDAI' breadcrumbItem='Profile' />
          <Row>
            <Col lg='12'>
              {error && error ? <Alert color='danger'>{error}</Alert> : null}
              {success ? <Alert color='success'>{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1 align-self-center'>
                      <div className='text-muted'>
                        <h5>{name || 'admin'}</h5>
                        <p className='mb-1'>{email || 'admin@gmail.com'}</p>
                        <p className='mb-0'>Id no: #{idx || 1}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h4 className='card-title mb-4'>Change User Name</h4>
          <Card>
            <CardBody>
              <Form
                className='form-horizontal'
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className='form-group'>
                  <Label className='form-label'>User Name</Label>
                  <Input
                    name='username'
                    className='form-control'
                    placeholder='Enter User Name'
                    type='text'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ''}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type='invalid'>{validation.errors.username}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className='text-center mt-4'>
                  <Button type='submit' color='primary'>
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <br></br>
          <h4 className='card-title mb-4'>2-Step Verification</h4>
          <Card>
            <CardBody>
              <div>
                <div className='form-check form-switch mb-3'>
                  {!mfaEnabled && (
                    <span className='form-check-label'>Turn 2-Step Verification on</span>
                  )}

                  {mfaEnabled && otpName === '' && (
                    <span className='form-check-label'>MFA Enabled</span>
                  )}

                  {mfaEnabled && otpName != '' && (
                    <span className='form-check-label'>
                      2-Step Verification enabled - ({otpName}) - Enrolled at {otpEnrollmentTime}
                    </span>
                  )}
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='customSwitch2'
                    checked={mfaEnabled}
                    onClick={(e) => {
                      setMfaEnabled(!mfaEnabled);
                    }}
                  />
                </div>
              </div>
              {mfaEnabled && otpName === '' && !error && (
                <Row>
                  <Col className='col-5'>
                    <div>
                      <p>
                        Configure your authenticator app by manually entering the information below,
                        or by scanning the QR code.
                      </p>
                      <table>
                        <tr>
                          <td>Code Name:</td>
                          <td className='px-2'>
                            <strong>PlanPort</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Key:</td>
                          <td className='px-2'>
                            <strong>{totpSecret?.secretKey}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Type of Key:</td>
                          <td className='px-2'>
                            <strong>Time based</strong>
                          </td>
                        </tr>
                      </table>

                      <div
                        style={{
                          height: 'auto',
                          marginTop: '25px',
                          margin: 'auto',
                          maxWidth: 200,
                          width: '100%',
                        }}
                      >
                        <QRCode
                          size={256}
                          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                          value={totpQRCode}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className='col-7'>
                    <h5 className='card-title mb-4'>
                      Please enter the latest code from your authenticator app to continue:
                    </h5>
                    <OtpInputCode otp={otpValidation} setOtp={setOtpValidation} />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
          Build number: {process.env.REACT_APP_BUILD_NUMBER}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
