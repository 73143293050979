import { getFirebaseBackend } from '../../../firebase';
import { userForgetPasswordError, userForgetPasswordSuccess } from './reducer';

export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
    const data = await getFirebaseBackend().forgetPassword(user.email);
    if (data) {
      dispatch(
        userForgetPasswordSuccess(
          'A password reset link has been sent to your email address. Please check your inbox.',
        ),
      );
    }
  } catch (forgetError) {
    dispatch(userForgetPasswordError(forgetError));
  }
};
