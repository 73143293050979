import { createSlice } from '@reduxjs/toolkit';

import { Plan } from '@helpers/firebase_helper';
import { fetchPlansByIds } from './thunk';

type SerializablePlan = Omit<Plan, 'updatedAt' | 'createdAt'>;

export interface PlanComparisonState {
  plans: SerializablePlan[] | null;
  loading: boolean;
  modals: {};
  layout: {
    selectedCategories: string[];
    categoryDropdown: boolean;
  };
  error: string;
}

const initialState: PlanComparisonState = {
  plans: [],
  loading: false,
  modals: {},
  layout: {
    selectedCategories: [],
    categoryDropdown: false,
  },
  error: '',
};

const PlanComparisonSlice = createSlice({
  name: 'planComparison',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    },
    setLoadingPlans: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setModals: (state, action) => {
      state.modals = { ...state.modals, ...action.payload };
    },
    setLayout: (state, action) => {
      state.layout = { ...state.layout, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansByIds.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPlansByIds.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlansByIds.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching plans by IDs';
        state.loading = false;
      });
  },
});

export const { setPlans, setLoadingPlans } = PlanComparisonSlice.actions;

export default PlanComparisonSlice.reducer;
