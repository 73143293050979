import { AppDispatch } from 'index';
import { PlanDetailsState } from 'slices/plan/planDetailsReducer';

export const setModals = (dispatch: AppDispatch, payload: Partial<PlanDetailsState['modals']>) => {
  dispatch({
    type: 'planDetails/setModals',
    payload,
  });
};

export const setError = (dispatch: AppDispatch, payload: string | null) => {
  dispatch({
    type: 'planDetails/setError',
    payload,
  });
};

export const setLayout = (dispatch: AppDispatch, payload: Partial<PlanDetailsState['layout']>) => {
  dispatch({
    type: 'planDetails/setLayout',
    payload,
  });
};
