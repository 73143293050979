import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { editTemplate } from 'slices/template/thunk';
import { getFirebaseBackend } from '../../../../../firebase';

interface EditTemplateDetailsModalProps {
  template: any;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  addNotification: (notification: string) => void;
}

const EditTemplateDetailsModal = ({
  template,
  isOpen,
  setIsOpen,
  addNotification,
}: EditTemplateDetailsModalProps) => {
  const dispatch = useDispatch<any>();
  const [templateName, setTemplateName] = useState<any>(template.templateName);
  const [shareTemplate, setShareTemplate] = useState<any>(!!template.organizationId);

  const handleEditTemplate = async () => {
    dispatch(editTemplate({ id: template.id!, templateName, shareTemplate })).then(() => {
      addNotification(`"${templateName}" was saved successfully.`);
      setIsOpen(false);
    });
  };

  const userOrganization = getFirebaseBackend().getCurrentOrganization();

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen}>
      <ModalHeader>Editing Template "{template.templateName}"</ModalHeader>
      <ModalBody>
        <div className='form-vertical'>
          <Label className='form-label'>Template Name</Label>
          <Input
            name='templateName'
            className='form-control'
            type='text'
            onChange={(x) => {
              setTemplateName(x.target.value);
            }}
            value={templateName}
          />
        </div>
        {!!userOrganization && (
          <div className='d-flex gap-2 mt-2'>
            <Label className='form-label align-self-center'>
              Share template with organization?
            </Label>
            <Input
              name='shareTemplate'
              className='form-control'
              type='checkbox'
              onChange={(x) => {
                setShareTemplate(x.target.checked);
              }}
              checked={shareTemplate}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button className='px-3' color='secondary' onClick={handleEditTemplate}>
          Save
        </Button>
        <Button
          className='px-3'
          color='gray-400'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Close without saving
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTemplateDetailsModal;
