import { CategoryList } from '@pdai/shared';
import { AppDispatch } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from 'reactstrap';
import { downloadProvisionComparisonExport } from '../../../helpers/export_provisions';
import { Plan } from '../../../helpers/firebase_helper';
import { setLayout } from '../dispatcher';

const PlanComparisonHeader = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { plans, selectedCategories, dropdownOpen } = useSelector((state: any) => ({
    plans: state.PlanComparison.plans as Plan[],
    selectedCategories: state.PlanComparison.layout.selectedCategories as string[],
    dropdownOpen: state.PlanComparison.layout.categoryDropdown as boolean,
  }));

  const setSelectedCategories = (selectedCategories: string[]) => {
    setLayout(dispatch, { selectedCategories });
  };

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const toggleCategoryDropdown = () => setLayout(dispatch, { categoryDropdown: !dropdownOpen });

  return (
    <div className='planDetail-top-section'>
      <Row>
        <Col className='col-8'>
          <Dropdown isOpen={dropdownOpen} toggle={toggleCategoryDropdown}>
            <div className='d-flex'>
              <DropdownToggle caret color='primary' className='category-filter'>
                <Row>
                  <Col className='col-10 text-start'>Select Categories</Col>
                  <Col className='col-2 text-end'>
                    <i className='mdi mdi-chevron-down px-1' />
                  </Col>
                </Row>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSelectedCategories([]);
                  }}
                >
                  Clear Filter
                </DropdownItem>
                <div className='dropdown-divider' />
                {CategoryList.map((category) => (
                  <DropdownItem
                    key={category}
                    onClick={() => handleCategoryClick(category)}
                    toggle={false}
                    disabled={
                      selectedCategories.length >= 3 && !selectedCategories.includes(category)
                    }
                    className='d-flex align-items-center'
                  >
                    <Input type='checkbox' checked={selectedCategories.includes(category)} />
                    <span className='px-3'>{category}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
              <div className='px-3'>{selectedCategories.join(', ')}</div>
            </div>
          </Dropdown>
        </Col>
        <Col className='col-4 text-end'>
          <Button
            type='button'
            color='primary'
            className='btn px-3 my-0'
            onClick={() => downloadProvisionComparisonExport(plans, selectedCategories)}
          >
            Export
          </Button>
          <Button
            type='button'
            color='light'
            className='btn px-4 my-0 mx-2'
            onClick={() => navigate(`/plans`)}
          >
            Back to My Plans
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PlanComparisonHeader;
