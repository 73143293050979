import { CategoryList, ProvisionAnswer } from '@pdai/shared';
import { AppDispatch } from 'index';
import { areLocationsAvailable } from 'pages/PlanList/planHelper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Table } from 'reactstrap';
import { updatePlanProvision } from 'slices/thunk';
import { Plan } from '../../../helpers/firebase_helper';
import { drawNewAnnotation } from '../../../helpers/PdfAnnotationsHelper';
import { formatProvisionAnswerAsHtml } from '../../../helpers/plan_helper';
import EditProvisionModal from '../modals/editProvisionModal';

interface PlanDetailsTableProps {
  plan: Plan;
  displayPDF: boolean;
  selectedCategories: string[];
  setErrorMessage: (value: string) => void;
  selectedProvision: ProvisionAnswer | null;
  setSelectedProvision: (value: ProvisionAnswer) => void;
}

const noEditInReviewedPlanText = `You cannot edit a provision while the plan status is "Reviewed". If you need to make any changes, you can move the plan status back to "Pending Review".`;

const showWaitCursor = (seconds: number, event) => {
  event.target.style.cursor = 'wait';
  document.body.style.cursor = 'wait';

  setTimeout(() => {
    document.body.style.cursor = 'default';
    event.target.style.cursor = 'pointer';
  }, seconds * 1000);
};

const convertToCamelCase = (sectionName: string): string => {
  return (
    sectionName ||
    ''
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};

const planHasLocations = (plan: Plan): boolean => {
  return (
    areLocationsAvailable(plan.status) && !!plan.answers && plan.answers[0].locations?.length > 0
  );
};

const PlanDetailsTable: React.FC<PlanDetailsTableProps> = ({
  plan,
  displayPDF,
  selectedCategories,
  setErrorMessage,
  selectedProvision,
  setSelectedProvision,
}: PlanDetailsTableProps) => {
  const dispatch: AppDispatch = useDispatch();

  const [categoriesReviewed, setCategoriesReviewed] = useState<{ [key: string]: boolean }>({});
  const [showEditProvisionModal, setShowEditProvisionModal] = useState(false);

  const [canvasBeforeAnnotation, setCanvasBeforeAnnotation] = useState<{
    pageIndex: number;
    imageData: ImageData;
    canvasContext: CanvasRenderingContext2D;
  } | null>(null);

  const refreshCategoriesReviewed = () => {
    const categoryStatus: { [key: string]: boolean } = {};

    // Collect all unique categories and their review status
    plan.answers?.forEach((answer) => {
      if (!(answer.category in categoryStatus)) {
        categoryStatus[answer.category] = true;
      }

      if (!answer.reviewed) {
        categoryStatus[answer.category] = false;
      }
    });
    setCategoriesReviewed(categoryStatus);
  };

  const handleReviewAllAnswersFromCategory = (category: string, reviewed: boolean, event) => {
    const categoryProvisions = plan.answers?.filter((answer) => answer.category === category);

    dispatch(
      updatePlanProvision({
        provisions: categoryProvisions!.map((provision) => {
          return {
            ...provision,
            reviewed: reviewed,
          };
        }),
        planId: plan.id!,
      }),
    );
  };

  const handleReviewAnswers = (provision: ProvisionAnswer, event) => {
    if (provision.reviewed && plan.status === 'reviewed') {
      setErrorMessage(noEditInReviewedPlanText);
      return;
    }

    dispatch(
      updatePlanProvision({
        provisions: [{ ...provision!, reviewed: !provision?.reviewed }],
        planId: plan.id!,
      }),
    );
    refreshCategoriesReviewed();
  };

  const handleEditAnswer = (provision: ProvisionAnswer) => {
    if (provision.reviewed && plan.status === 'reviewed') {
      setErrorMessage(noEditInReviewedPlanText);
      return;
    }
    setSelectedProvision(provision);
    setShowEditProvisionModal(true);
  };

  const handleClickLocation = async (provision: ProvisionAnswer, index) => {
    if (canvasBeforeAnnotation) {
      if (canvasBeforeAnnotation.imageData) {
        canvasBeforeAnnotation.canvasContext.putImageData(canvasBeforeAnnotation.imageData, 0, 0);
      }
    }

    // get new annotation page canvas.
    const canvas = document.getElementById(
      `canvas-page-${provision.locations[index].pageNumber + 1}`,
    ) as HTMLCanvasElement;
    const canvasContext = canvas!.getContext('2d')!;

    // save canvas state before notification.
    const lastAnnotationCanvasState = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
    setCanvasBeforeAnnotation({
      pageIndex: provision.locations[index].pageNumber + 1,
      imageData: lastAnnotationCanvasState,
      canvasContext: canvasContext,
    });

    const { y } = drawNewAnnotation(provision, index, canvas, canvasContext);

    // scroll to annotation
    const containerDiv = document.getElementById(`pdfViewerForAnnotation`) as HTMLDivElement;

    // 1. Get the canvas and target coordinates
    const rect = canvas.getBoundingClientRect();
    const targetY = rect.top + y;

    // 2. Calculate the center position within the parent div
    const centerY = containerDiv.offsetHeight / 2;

    // 3. Calculate the scroll offset
    const scrollY = targetY - centerY;

    // 4. Scroll the parent div
    containerDiv.scrollBy({
      left: 0,
      top: scrollY - 200,
      behavior: 'smooth', // Optional for smooth scrolling
    });
  };

  return (
    <>
      <div className={`${displayPDF ? '' : 'mx-3'}`}>
        <Table className={`${displayPDF ? 'm-3 provision-table' : 'provision-table-full'}`}>
          {CategoryList.map((category) => {
            return selectedCategories.length === 0 || selectedCategories.includes(category) ? (
              <tbody key={category} className='my-5'>
                <tr key={`${category}-firstHeader`}>
                  <th
                    className='px-4 provision-category-header-first-row'
                    colSpan={displayPDF ? 3 : 2}
                  >
                    {category}
                  </th>
                  <th
                    className='px-3 provision-category-header-first-row'
                    colSpan={displayPDF ? 1 : 2}
                  >
                    <Row>
                      {plan.status !== 'processingLocationOCR' &&
                        plan.status !== 'processingLocationAI' &&
                        displayPDF && (
                          <Col className='col-6 px-3 py-3'>
                            <a
                              title={
                                categoriesReviewed[category]
                                  ? `Mark all provisions from "${category}" category as pending review`
                                  : `Mark all provisions from "${category}" category as reviewed`
                              }
                            >
                              <Button
                                type='button'
                                className={`btn ${
                                  categoriesReviewed[category]
                                    ? 'edit-provision-button-active'
                                    : 'edit-provision-button'
                                }`}
                                onClick={(event) => {
                                  handleReviewAllAnswersFromCategory(
                                    category,
                                    !categoriesReviewed[category],
                                    event,
                                  );
                                }}
                              >
                                <i className='mdi mdi-check'></i>
                              </Button>
                            </a>
                          </Col>
                        )}{' '}
                      {plan.status !== 'processingLocationOCR' &&
                        plan.status !== 'processingLocationAI' &&
                        !displayPDF && (
                          <Col className='col-12 py-3 text-end'>
                            <Button
                              type='button'
                              color='gray-400'
                              className='btn my-0 w-75 mx-2'
                              onClick={(event) => {
                                handleReviewAllAnswersFromCategory(
                                  category,
                                  !categoriesReviewed[category],
                                  event,
                                );
                              }}
                            >
                              {categoriesReviewed[category]
                                ? `Mark provisions as Pending Review`
                                : `Mark provisions as Reviewed`}
                            </Button>
                          </Col>
                        )}
                    </Row>
                  </th>
                </tr>
                <tr key={`${category}-secondHeader`}>
                  <th className='px-4 provision-label-header'>Provision</th>
                  <th className='provision-label-header'>Approved Version</th>

                  {planHasLocations(plan) && displayPDF && (
                    <th className='provision-label-header text-center'>Locations</th>
                  )}
                  {planHasLocations(plan) && !displayPDF && (
                    <th className='provision-label-header provision-label-header-locations text-center'>
                      Page & Section
                    </th>
                  )}
                  {!planHasLocations(plan) && (
                    <th className='provision-label-header text-center'></th>
                  )}
                  <th className='provision-label-header provision-label-header-actions text-center'>
                    Actions
                  </th>
                </tr>
                {plan.answers &&
                  plan.answers?.map((provision) => {
                    if (provision.category === category) {
                      return (
                        <tr key={provision.provisionName} className='align-middle'>
                          {/* Display Provision Label */}
                          <td className='px-4'>{provision.provisionLabel}</td>

                          {/* Display Provision Answers (user or model) */}
                          {provision.userAnswer && (
                            <td className='edited-provision'>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatProvisionAnswerAsHtml(provision.userAnswer),
                                }}
                              />
                            </td>
                          )}
                          {!provision.userAnswer && (
                            <td>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatProvisionAnswerAsHtml(provision.modelAnswer),
                                }}
                              />
                            </td>
                          )}

                          {planHasLocations(plan) && displayPDF && (
                            <td>
                              {provision.locations &&
                                provision.locations.map((location, index) => {
                                  return (
                                    <Button
                                      type='button'
                                      className='btn find-in-document-button mx-4'
                                      onClick={() => {
                                        handleClickLocation(provision, index);
                                      }}
                                    >
                                      {index + 1}
                                    </Button>
                                  );
                                })}
                            </td>
                          )}

                          {planHasLocations(plan) && !displayPDF && (
                            <td>
                              {provision.locations &&
                                provision.locations.map((location) => {
                                  return (
                                    <>
                                      <strong>Page:</strong> {location.pageNumber + 1}
                                      {' - '}
                                      <strong>Section:</strong>{' '}
                                      {convertToCamelCase(location.section)}
                                      <br></br>
                                    </>
                                  );
                                })}
                            </td>
                          )}
                          {!planHasLocations(plan) && <td></td>}

                          <td className='px-3'>
                            <Row>
                              <Col className='col-6 px-3'>
                                <Button
                                  type='button'
                                  className={`btn ${
                                    provision.reviewed
                                      ? 'review-provision-button-active'
                                      : 'edit-provision-button'
                                  }`}
                                  onClick={async (event) => {
                                    handleReviewAnswers(provision, event);
                                  }}
                                >
                                  <i className='mdi mdi-check'></i>
                                </Button>
                              </Col>
                              <Col className='col-6 '>
                                <Button
                                  type='button'
                                  className='btn edit-provision-button'
                                  onClick={async () => {
                                    handleEditAnswer(provision);
                                  }}
                                >
                                  <i className='mdi mdi-pencil-box-outline'></i>
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </tbody>
            ) : (
              <></>
            );
          })}
        </Table>
      </div>
      <EditProvisionModal
        isOpen={showEditProvisionModal}
        toggle={() => setShowEditProvisionModal(false)}
        plan={plan}
        provision={selectedProvision}
      />
    </>
  );
};

export default PlanDetailsTable;
