export const loadExcelJS = async () => {
  try {
    const exceljsModule = await import('exceljs');
    return exceljsModule;
  } catch (error) {
    console.error('Error loading exceljs:', error);
    return null;
  }
};

export const loadDocx = async () => {
  try {
    const docxModule = await import('docx');
    return docxModule;
  } catch (error) {
    console.error('Error loading docx:', error);
    return null;
  }
};
