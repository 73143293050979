import { CategoryList } from '@pdai/shared';
import { PDAIProvisions } from '@pdai/shared/dist/provisions';
import * as docx from 'docx';
import { downloadFile, downloadXLSX } from './file_download';
import { Plan } from './firebase_helper';
import { loadExcelJS } from './lazyLoadLibraries';
import { getPlanName } from './plan_helper';
// TODO: error treatment when no answers are available
// TODO: error treatment when no replacement was made

const Excel = loadExcelJS();

export const generateExcelProvisions = async (plan: Plan) => {
  const workbook = new (await Excel)!.Workbook();
  workbook.creator = 'PlanPort - PlanDataAI';
  workbook.lastModifiedBy = 'PlanPort - PlanDataAI';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const sheet = workbook.addWorksheet(`${getPlanName(plan)}`);

  // Group answers by category
  const groupedAnswers = plan.answers!.reduce((acc, answer) => {
    const category = answer.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({
      Provisions: answer.provisionLabel,
      Answer: answer.modelAnswer,
    });
    return acc;
  }, {} as { [key: string]: any[] });

  const categoryRows: number[] = [];
  const provisionRows: number[] = [];

  // Add category headers and answers to the data array with the new format
  for (const category in groupedAnswers) {
    sheet.addRow([category]);
    categoryRows.push(sheet.rowCount);

    groupedAnswers[category].forEach((answer) => {
      sheet.addRow([answer.Provisions, answer.Answer]);
      provisionRows.push(sheet.rowCount);
    });
  }

  const getLineCount = (text: string, maxCharsPerLine: number = 60): number => {
    if (!text) {
      return 1;
    }

    const lines = text.split('\n');
    let lineCount = lines.length;

    for (const line of lines) {
      if (line.length > maxCharsPerLine) {
        lineCount += Math.floor(line.length / maxCharsPerLine);
      }
    }

    return lineCount;
  };

  provisionRows.forEach((rowNumber) => {
    const provisionRow = sheet.getRow(rowNumber);
    provisionRow.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    provisionRow.height = getLineCount(provisionRow.getCell(2).value!.toString(), 102) * 18;

    provisionRow.getCell(1).border = { bottom: { style: 'hair' } };
    provisionRow.getCell(2).border = { bottom: { style: 'hair' } };
  });

  categoryRows.forEach((rowNumber) => {
    const categoryRow = sheet.getRow(rowNumber);
    categoryRow.font = { bold: true };
    categoryRow.height = 30;
    categoryRow.font.color = { argb: 'ffffffff' };

    categoryRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(1).border = { bottom: { style: 'thin' } };
    categoryRow.getCell(2).border = { bottom: { style: 'thin' } };

    sheet.mergeCells(rowNumber, 1, rowNumber, 2);
  });

  sheet.columns[0].width = 50;
  sheet.columns[1].width = 90;

  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a temporary URL for the blob
  return window.URL.createObjectURL(blob);
};

export const generateExcelComparisonProvisions = async (
  plans: Plan[],
  selectedCategories: string[] = [],
) => {
  const workbook = new (await Excel)!.Workbook();
  workbook.creator = 'PlanPort - PlanDataAI';
  workbook.lastModifiedBy = 'PlanPort - PlanDataAI';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const amountOfPlans = plans.length;
  const planNames = plans.map((plan, i) => getPlanName(plan) || `Plan ${i + 1}`);

  const sheet = workbook.addWorksheet(`PlanData Comparison`);

  const categoryProvisions =
    selectedCategories.length > 0
      ? PDAIProvisions.map((provision) => ({
          category: provision.category,
          provisionName: provision.provisionName,
          provisionLabel: provision.provisionLabel,
        })).filter((provision) => selectedCategories.includes(provision.category))
      : PDAIProvisions.map((provision) => ({
          category: provision.category,
          provisionName: provision.provisionName,
          provisionLabel: provision.provisionLabel,
        }));

  const groupedAnswers = categoryProvisions.reduce((acc, provRow) => {
    if (!acc[provRow.category]) {
      acc[provRow.category] = [];
    }
    const answers = plans.map((plan) => {
      const answer = plan.answers?.find((ans) => ans.provisionName == provRow.provisionName);
      return answer?.modelAnswer;
    });
    if (answers.some((ans) => !!ans)) {
      acc[provRow.category].push({
        provision: provRow.provisionLabel,
        answers,
      });
    }
    return acc;
  }, {} as { [key: string]: any[] });

  const categoryRows: number[] = [];
  const comparisonRows: number[] = [];
  const provisionRows: number[] = [];

  // Add category headers and answers to the data array with the new format
  for (const category in groupedAnswers) {
    sheet.addRow([category]);
    categoryRows.push(sheet.rowCount);
    sheet.addRow([]);
    comparisonRows.push(sheet.rowCount);

    groupedAnswers[category].forEach((answerRow) => {
      sheet.addRow([answerRow.provision, ...answerRow.answers]);
      provisionRows.push(sheet.rowCount);
    });
  }

  const getLineCount = (text: string, maxCharsPerLine: number = 60): number => {
    if (!text) {
      return 1;
    }

    const lines = text.split('\n');
    let lineCount = lines.length;

    for (const line of lines) {
      if (line.length > maxCharsPerLine) {
        lineCount += Math.floor(line.length / maxCharsPerLine);
      }
    }

    return lineCount;
  };

  provisionRows.forEach((rowNumber) => {
    const provisionRow = sheet.getRow(rowNumber);
    provisionRow.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    provisionRow.height = getLineCount(provisionRow.getCell(2).value!.toString(), 102) * 18;

    provisionRow.getCell(1).border = { bottom: { style: 'hair' } };
    provisionRow.getCell(2).border = { bottom: { style: 'hair' } };
  });

  categoryRows.forEach((rowNumber) => {
    const categoryRow = sheet.getRow(rowNumber);
    categoryRow.font = { bold: true };
    categoryRow.height = 30;
    categoryRow.font.color = { argb: 'ffffffff' };
    categoryRow.font.size = 14;
    categoryRow.alignment = { vertical: 'middle' };

    for (let i = 1; i <= amountOfPlans + 1; i++) {
      categoryRow.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0b628d' },
      };
    }

    sheet.mergeCells(rowNumber, 1, rowNumber, amountOfPlans + 1);
  });

  comparisonRows.forEach((rowNumber) => {
    const comparisonRow = sheet.getRow(rowNumber);

    comparisonRow.font = { bold: true };
    comparisonRow.font.color = { argb: 'ffffffff' };
    comparisonRow.font.size = 12;

    for (let i = 1; i <= amountOfPlans + 1; i++) {
      comparisonRow.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0b628d' },
      };
      comparisonRow.getCell(i).border = { bottom: { style: 'thin' } };
      comparisonRow.getCell(i).value = i == 1 ? 'Provision' : planNames[i - 2];
    }
  });

  sheet.columns[0].width = 50;
  for (let i = 1; i <= amountOfPlans; i++) {
    sheet.columns[i].width = 90;
  }

  // Freeze the first column
  sheet.views = [
    {
      state: 'frozen',
      xSplit: 1,
      ySplit: 0,
      topLeftCell: 'B1',
      activeCell: 'A1',
    },
  ];

  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a temporary URL for the blob
  return window.URL.createObjectURL(blob);
};

export const downloadProvisionsTemplate = async () => {
  const emptyPlan = {
    answers: PDAIProvisions.map((provision) => ({
      category: provision.category,
      provisionName: provision.provisionName,
      provisionLabel: provision.provisionLabel,
      modelAnswer: `\${${provision.provisionName}}`,
      locations: [],
    })),
    fileName: 'plandata_export_template.xlsx',
    userId: '',
    status: '',
  };

  const template = await fetch(await generateExcelProvisions(emptyPlan));

  const workbook = new (await Excel)!.Workbook();
  await workbook.xlsx.load(await template.arrayBuffer());

  await downloadXLSX(workbook, `plandata_export_template.xlsx`);
};

export const downloadProvisionsExport = async (plan: Plan, templateURL?: string) => {
  const template = await fetch(templateURL || (await generateExcelProvisions(plan)));

  const workbook = new (await Excel)!.Workbook();
  await workbook.xlsx.load(await template.arrayBuffer());

  const answerMap = (plan.answers || [])?.reduce((acc, answer) => {
    acc.set(answer.provisionName, answer.modelAnswer);
    return acc;
  }, new Map<string, string>());

  workbook.worksheets.forEach((sheet) => {
    sheet?.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        if (typeof cell.value === 'string' && cell.value.includes('${')) {
          const key = cell.value.match(/\${(.*?)}/)?.[1];
          if (key && answerMap.has(key)) {
            cell.value = cell.value.replace(`\${${key}}`, answerMap.get(key)!);
            // console.log(`(${rowNumber},${colNumber}): "${key}" ---> "${cell.value}"`);
          }
        }
      });
    });
  });

  await downloadXLSX(workbook, `${getPlanName(plan)}_export.xlsx`);
};

export const downloadProvisionsExportWord = async (plan: Plan) => {
  const externalStyles = await fetch(`${process.env.PUBLIC_URL}/docx-styles.xml`).then((res) =>
    res.text(),
  );

  const docChildren: docx.FileChild[] = [];
  const SPACING_SINGLE = { before: 100, after: 100 };
  const SPACING_DOUBLE = { before: 200, after: 200 };

  docChildren.push(
    new docx.Paragraph({
      children: [new docx.TextRun({ text: 'PlanPort', bold: true })],
      heading: docx.HeadingLevel.HEADING_1,
      spacing: SPACING_DOUBLE,
    }),
  );

  docChildren.push(
    new docx.Paragraph({
      children: [new docx.TextRun({ text: getPlanName(plan), bold: true })],
      heading: docx.HeadingLevel.HEADING_1,
      spacing: SPACING_DOUBLE,
    }),
  );

  const createProvisionNameCell = (content: string): docx.TableCell => {
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          children: [new docx.TextRun({ text: content })],
        }),
      ],
    });
  };

  const createProvisionValueCell = (content: string): docx.TableCell => {
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          children: [new docx.TextRun({ text: content })],
        }),
      ],
    });
  };
  const createCategoryCell = (content: string): docx.TableCell => {
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          children: [new docx.TextRun({ text: content, bold: true })],
        }),
      ],
      columnSpan: 2,
    });
  };

  const newRows: docx.TableRow[] = [];

  CategoryList.forEach((category) => {
    newRows.push(new docx.TableRow({ children: [createCategoryCell(category)] }));

    plan.answers?.forEach((answer) => {
      if (answer.category === category) {
        newRows.push(
          new docx.TableRow({
            children: [
              createProvisionNameCell(answer.provisionLabel),
              createProvisionValueCell(answer.userAnswer || answer.modelAnswer),
            ],
          }),
        );
      }
    });
  });

  const docTable = new docx.Table({
    rows: newRows,
    width: { type: docx.WidthType.PERCENTAGE, size: '100%' },
  });

  docChildren.push(docTable);

  const doc = new (await docx)!.Document({
    sections: [
      {
        properties: {},
        children: docChildren,
      },
    ],
    externalStyles,
  });

  const blob = await (await docx)!.Packer.toBlob(doc);

  await downloadFile(blob, `${getPlanName(plan)}_export.docx`);
};

export const downloadProvisionComparisonExport = async (
  plans: Plan[],
  selectedCategories: string[],
) => {
  const template = await fetch(await generateExcelComparisonProvisions(plans, selectedCategories));

  const workbook = new (await Excel)!.Workbook();
  await workbook.xlsx.load(await template.arrayBuffer());

  await downloadXLSX(workbook, 'plandata_compare_plans.xlsx');
};
