import Spinners from 'Components/Common/Spinner';
import { AppDispatch } from 'index';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap';
import { FeedbackState } from 'slices/feedback/reducer';
import { getFirebaseBackend } from '../../firebase';

const FeedbackFAB = () => {
  const dispatch: AppDispatch = useDispatch();

  const fabRef = useRef<HTMLButtonElement>(null);
  const isDragging = useRef(false);
  const dragStartY = useRef(0);

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging.current && fabRef.current) {
      fabRef.current.style.transition = 'none';
      const newTop = e.clientY - dragStartY.current;

      const minTop = 0;
      const maxTop = window.innerHeight - fabRef.current.offsetHeight;
      const boundedTop = Math.max(minTop, Math.min(maxTop, newTop));

      fabRef.current.style.top = boundedTop + 'px';
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    isDragging.current = true;
    dragStartY.current = e.clientY - e.currentTarget.getBoundingClientRect().top;
    console.log(dragStartY.current);

    e.preventDefault();
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const setState = (payload: Partial<FeedbackState>) => {
    dispatch({
      type: 'feedback/setState',
      payload,
    });
  };

  const { feedback, sendingFeedback, modalOpen, popoverOpen, buttonFocused } = useSelector(
    (state: any) => ({
      feedback: state.Feedback.message as string,
      sendingFeedback: state.Feedback.loading as boolean,
      modalOpen: state.Feedback.thankYouMessage as boolean,
      popoverOpen: state.Feedback.popoverOpen as boolean,
      buttonFocused: state.Feedback.fabFocused as boolean,
    }),
  );

  const setPopoverOpen = (value: boolean) => {
    setState({ popoverOpen: value });
  };

  const closeModal = () => {
    setState({ thankYouMessage: false });
  };

  const setButtonFocused = (value: boolean) => {
    setState({ fabFocused: value });
  };

  const toggle = () => setPopoverOpen(!popoverOpen);

  const fabStyle: React.CSSProperties = {
    position: 'fixed',
    width: '40px',
    height: '80px',
    bottom: '20px',
    opacity: buttonFocused || popoverOpen ? '1' : '0.5',
    right: popoverOpen ? '50%' : '0px',
    zIndex: '1051',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    transition: popoverOpen ? 'all 0.4s 0.3s' : 'all 0.4s 0s',
    borderRadius: '15px 0px 0px 15px',
    cursor: 'grab',
    fontSize: '26px',
    paddingLeft: '7px',
  };

  const handleFeedbackSubmit = async () => {
    setState({ loading: true });

    const firebaseBackend = getFirebaseBackend();
    firebaseBackend.sendFeedback(feedback).then(() => {
      setState({
        loading: false,
        message: '',
        popoverOpen: false,
        thankYouMessage: true,
      });
    });
  };

  return (
    <>
      <Button
        id='FeedbackButton'
        color='secondary'
        style={fabStyle}
        onClick={toggle}
        onMouseEnter={() => setButtonFocused(true)}
        onMouseLeave={() => setButtonFocused(false)}
        innerRef={fabRef}
        onMouseDown={handleMouseDown}
      >
        <span className='fas fa-comment' />
      </Button>
      <Offcanvas className='w-50' direction='end' isOpen={popoverOpen} toggle={toggle}>
        <OffcanvasHeader>
          <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>Need Help with PlanPort?</span>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div style={{ fontSize: '1.1rem' }}>
            Experiencing an issue or have feedback to share? <br></br>
            Let us know! <br></br>
            <br></br>
            Please, describe what's happening and what you'd expect to see. <br></br>
            We'll be in touch soon.
          </div>
          <Input
            className='my-4'
            style={{ fontSize: '1.1rem' }}
            type='textarea'
            placeholder='Describe your issue or feedback here...'
            rows={5}
            onChange={(e) => setState({ message: e.target.value })}
            value={feedback}
          />
          <Button
            color='primary'
            disabled={!feedback || sendingFeedback}
            onClick={handleFeedbackSubmit}
          >
            Send
          </Button>
          {sendingFeedback && <Spinners />}
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader>
          <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>Message Received!</span>
        </ModalHeader>
        <ModalBody style={{ fontSize: '1.1rem' }}>
          <p>Thanks for reaching out! We appreciate you taking the time to share your feedback.</p>
          <p>We'll review it carefully and use it to improve PlanPort.</p>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FeedbackFAB;
