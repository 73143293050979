import { ExportTemplate } from '@helpers/firebase_helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFirebaseBackend } from '../../firebase';
import { setTemplates } from './reducer';

// Fetch logged user's templates
export const fetchExportTemplates = createAsyncThunk(
  'templates/fetchExportTemplates',
  async (_, { dispatch }) => {
    try {
      const firebase = getFirebaseBackend();
      const [userTemplates, orgTemplates] = await Promise.all([
        firebase.getUserExportTemplates(),
        firebase.getOrgExportTemplates(),
      ]);

      dispatch(setTemplates([...orgTemplates, ...userTemplates]));
    } catch (error) {
      console.error('Error fetching user export templates:', error);
      throw error;
    }
  },
);

// Delete specific template
export const deleteTemplate = createAsyncThunk(
  'templates/delete',
  async ({ id }: { id: string }): Promise<string> => {
    await getFirebaseBackend().deleteTemplate(id);
    return id;
  },
);

// Rename specific template
export const editTemplate = createAsyncThunk(
  'templates/edit',
  async ({
    id,
    templateName,
    shareTemplate,
  }: {
    id: string;
    templateName: string;
    shareTemplate: boolean;
  }): Promise<Partial<ExportTemplate>> => {
    return await getFirebaseBackend().editTemplate(id, templateName, shareTemplate);
  },
);
