import { createSlice } from '@reduxjs/toolkit';
import { ExportTemplate } from '../../helpers/firebase_helper';
import { deleteTemplate, editTemplate } from './thunk';

interface TemplatesState {
  templates: ExportTemplate[];
  modals: {
    createTemplate: boolean;
    editTemplates: boolean;
    editTemplateDetails: boolean;
  };
  layout: {
    templateListDropdown: boolean;
  };
  loading: boolean;
  error: string | null;
}

const initialState: TemplatesState = {
  templates: [],
  modals: {
    createTemplate: false,
    editTemplates: false,
    editTemplateDetails: false,
  },
  layout: {
    templateListDropdown: false,
  },
  loading: false,
  error: null,
};

const TemplatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload.sort(
        (a: ExportTemplate, b: ExportTemplate) => b.updatedAt.toMillis() - a.updatedAt.toMillis(),
      );
      state.loading = false;
    },
    setLoadingTemplates: (state, action) => {
      state.loading = action.payload;
    },
    setModals: (state, action) => {
      state.modals = { ...state.modals, ...action.payload };
    },
    setLayout: (state, action) => {
      state.layout = { ...state.layout, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(deleteTemplate.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.templates = state.templates.filter((template) => template.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.error = action.error.message || 'Error deleting template';
      })
      .addCase(editTemplate.pending, (state) => {
        state.error = null;
      })
      .addCase(editTemplate.fulfilled, (state, action) => {
        state.templates = state.templates.map((template) =>
          template.id === action.payload.id ? { ...template, ...action.payload } : template,
        );
        state.loading = false;
      })
      .addCase(editTemplate.rejected, (state, action) => {
        state.error = action.error.message || 'Error editing template';
      });
  },
});

export const { setTemplates, setLoadingTemplates } = TemplatesSlice.actions;

export default TemplatesSlice.reducer;
