import { Plan } from '@helpers/firebase_helper';
import { PDAICollections, PlanStatus, ProvisionAnswer } from '@pdai/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { mergePlanLists } from 'pages/PlanList/planHelper';
import { getFirebaseBackend } from '../../firebase';
import { setProvisions } from './planDetailsReducer';
import { setLoadingPlans, setPlans } from './planListReducer';

export const updatePlan = createAsyncThunk(
  'plans/update',
  async ({
    id,
    updatedData,
  }: {
    id: string;
    updatedData: Partial<Plan>;
  }): Promise<Partial<Plan>> => {
    const response = getFirebaseBackend().updatePlan(id, updatedData);
    return response;
  },
);

export const selectPlan = createAsyncThunk(
  'plans/select',
  async (selectedId: string): Promise<string> => {
    return selectedId;
  },
);

export const subscribeToPlans = createAsyncThunk('plans/subscribe', async (_, { dispatch }) => {
  try {
    dispatch(setLoadingPlans(true));
    const firebaseHelper = getFirebaseBackend();
    const currentUser = firebaseHelper.getCurrentUser();
    const currentOrgId = firebaseHelper.getCurrentOrganization();

    let orgPlans: any = [];

    if (currentOrgId && currentOrgId != 'undefined') {
      // Get all users associated with the organization
      const usersSnapshot = await getDocs(
        query(collection(firebaseHelper.db, 'users'), where('organizationId', '==', currentOrgId)),
      );

      const users = usersSnapshot.docs.map((userDoc) => ({
        userId: userDoc.id,
        email: userDoc.data().email,
      }));

      const orgPlansSnapshot = await getDocs(
        query(
          collection(firebaseHelper.db, PDAICollections.Plans),
          where('organizationId', '==', currentOrgId),
          where('status', 'not-in', [
            PlanStatus.Failed,
            PlanStatus.Deleted,
            PlanStatus.ProcessingAI,
            PlanStatus.ProcessingLocationAI,
            PlanStatus.ProcessingLocationOCR,
            PlanStatus.ProcessingText,
            PlanStatus.Uploading,
          ]),
          orderBy('createdAt', 'desc'),
        ),
      );

      orgPlans = await Promise.all(
        orgPlansSnapshot.docs.map(async (doc) => {
          const planData = doc.data() as Plan;

          const docEmail = users.filter((user) => user.userId == planData.userId)[0];
          let userEmail = '(email address not available)';
          if (docEmail) {
            userEmail = docEmail.email;
          }

          if (planData.fileUri) {
            const storageRef = ref(
              firebaseHelper.storage,
              planData.fileUri.replace(
                'gs://' + firebaseHelper.firebaseConfig.storageBucket + '/',
                '',
              ),
            );
            const downloadUrl = await getDownloadURL(storageRef);

            return {
              ...planData,
              id: doc.id,
              downloadUrl,
              userEmail: userEmail,
            };
          } else {
            return {
              ...planData,
              id: doc.id,
              userEmail: userEmail,
            };
          }
        }),
      );
    }

    const q = query(
      collection(firebaseHelper.db, PDAICollections.Plans),
      where('userId', '==', currentUser.uid),
      where('status', '!=', PlanStatus.Deleted),
      orderBy('createdAt', 'desc'),
    );

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        const updatedDocuments = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const planData = doc.data() as Plan;

            if (planData.fileUri) {
              const storageRef = ref(
                firebaseHelper.storage,
                planData.fileUri.replace(
                  'gs://' + firebaseHelper.firebaseConfig.storageBucket + '/',
                  '',
                ),
              );
              const downloadUrl = await getDownloadURL(storageRef);

              return {
                ...planData,
                userEmail: currentUser.email,
                id: doc.id,
                downloadUrl,
              };
            } else {
              return {
                ...planData,
                id: doc.id,
                userEmail: currentUser.email,
              };
            }
          }),
        );
        console.log('userPlans', updatedDocuments);
        console.log('orgPlans', orgPlans);
        dispatch(setPlans(mergePlanLists(updatedDocuments, orgPlans)));
      },
      (error) => {
        console.error('Error fetching documents:', error);
      },
    );
    return unsubscribe;
  } catch (error) {
    console.error('Error subscribing to documents:', error);
    throw error;
  }
});

export const updatePlanProvision = createAsyncThunk(
  'plans/updatePlanProvision',
  async (
    {
      provisions,
      planId,
    }: {
      provisions: ProvisionAnswer[];
      planId: string;
    },
    { dispatch },
  ): Promise<{ provisions: ProvisionAnswer[]; planId: string }> => {
    dispatch(setProvisions({ provisions, planId }));
    await getFirebaseBackend().updatePlanWithProvisionAnswer(provisions, planId);
    return { provisions, planId };
  },
);

// Fetch multiple plans by their IDs
export const fetchPlansByIds = createAsyncThunk(
  'plans/fetchPlansByIds',
  async (planIds: string[]): Promise<Plan[]> => {
    try {
      const firebaseHelper = getFirebaseBackend();
      const plans: Plan[] = [];

      // Fetch each plan individually
      for (const planId of planIds) {
        const planDoc = await getDoc(doc(firebaseHelper.db, 'adoptionAgreements', planId));
        if (!planDoc.exists) {
          throw new Error('Plan not found: ' + planId);
        }

        const planData = planDoc.data() as Plan;
        plans.push({
          ...planData,
          id: planDoc.id,
        });
      }
      return plans;
    } catch (error) {
      console.error('Error fetching plans by IDs:', error);
      throw error;
    }
  },
);

// Fetch a specific plan by ID
export const fetchPlanById = createAsyncThunk(
  'plans/fetchById',
  async (planId: string): Promise<Plan> => {
    try {
      const firebaseHelper = getFirebaseBackend();

      // Fetch the plan from Firestore
      const planDoc = await getDoc(doc(firebaseHelper.db, 'adoptionAgreements', planId));
      if (!planDoc.exists) {
        throw new Error('Plan not found: ' + planId);
      }

      const planData = planDoc.data() as Plan;

      if (planData.fileUri) {
        const storageRef = ref(
          firebaseHelper.storage,
          planData.fileUri.replace('gs://' + firebaseHelper.firebaseConfig.storageBucket + '/', ''),
        );
        const downloadUrl = await getDownloadURL(storageRef);

        return {
          ...planData,
          id: planDoc.id,
          downloadUrl,
        };
      } else {
        return {
          ...planData,
          id: planDoc.id,
        };
      }
    } catch (error) {
      console.error('Error fetching plan by ID:', error);
      throw error;
    }
  },
);
