import { PDAIProvisions } from '@pdai/shared/dist/provisions';
import Spinners from 'Components/Common/Spinner';
import { DocumentData, DocumentReference } from 'firebase/firestore';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, Col, Form, Input, Label, Row } from 'reactstrap';
import { getFirebaseBackend } from '../../../../../firebase';
import { loadExcelJS } from '../../../../../helpers/lazyLoadLibraries';

const Excel = loadExcelJS();

interface UploadTemplateDropZoneProps {
  onCompleteUpload: (arg0?: DocumentReference<DocumentData, DocumentData>) => Promise<void>;
}

const validateUploadedFile = async (file: any) => {
  const workbook = new (await Excel)!.Workbook();
  await workbook.xlsx.load(await file.arrayBuffer());

  let hasKey = false;
  workbook.worksheets.forEach((sheet) => {
    sheet?.eachRow((row) => {
      row.eachCell((cell) => {
        if (typeof cell.value === 'string' && cell.value.includes('${')) {
          const key = cell.value.match(/\${(.*?)}/)?.[1];
          if (key && PDAIProvisions.map((p) => p.provisionName).includes(key)) {
            hasKey = true;
          }
        }
      });
    });
  });

  if (!hasKey) {
    throw new Error('No provision placeholders found in the uploaded file.');
  }
};

const UploadTemplateDropZone: React.FC<UploadTemplateDropZoneProps> = ({ onCompleteUpload }) => {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [error, setError] = useState<string>('');

  const [templateName, setTemplateName] = useState<string>('');
  const [shareTemplate, setShareTemplate] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  function handleAcceptedFiles(file: any) {
    Object.assign(file, {
      formattedSize: formatBytes(file.size),
    });
    setSelectedFile(file);
    setTemplateName(file.name.replace(/\.[^/.]+$/, ''));
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const handleFileUpload = async () => {
    setError('');
    setIsUploading(true);

    if (!selectedFile) return;
    try {
      await validateUploadedFile(selectedFile);
    } catch (error) {
      console.error('Failed to upload file:', error);
      setError(String(error));
      return;
    }

    const firebaseHelper = getFirebaseBackend();

    const uploadedFile = await firebaseHelper.uploadTemplateToStorage(
      selectedFile,
      templateName,
      shareTemplate,
    );

    console.log('File uploaded successfully:', selectedFile.name);
    setIsUploading(false);
    await onCompleteUpload(uploadedFile);
  };

  const handleDelete = () => {
    setError('');
    setSelectedFile(null);
  };

  const userOrganization = getFirebaseBackend().getCurrentOrganization();

  return (
    <Form className='pb-4'>
      {!selectedFile && (
        <Dropzone
          multiple={false}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          }}
          onDrop={(acceptedFile: any) => {
            handleAcceptedFiles(acceptedFile[0]);
          }}
        >
          {({ getRootProps, getInputProps }: any) => (
            <div className='upload-file-dropzone'>
              <div className='dz-message needsclick mt-2' {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='mb-3'>
                  <i className='display-4 text-muted bx bxs-cloud-upload' />
                </div>
                <h4>Drag and drop the file here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      <div className='mt-3'>
        {selectedFile && (
          <div className='d-flex flex-column gap-2 mt-2'>
            <Card className='mt-1 mb-0 shadow-none border'>
              <div className='p-2'>
                <Row className='align-items-center me-3'>
                  <Col className='col-11 text-start'>
                    <p className='fs-5 mb-0'>{selectedFile.name}</p>
                    <p className='mb-0'>{selectedFile.formattedSize}</p>
                  </Col>
                  <Col className='col-1 text-end'>
                    <i className='mdi mdi-delete-outline delete-file-icon' onClick={handleDelete} />
                  </Col>
                </Row>
              </div>
            </Card>
            <div className='form-vertical mt-3'>
              <Label className='form-label'>Template Name</Label>
              <Input
                name='templateName'
                className='form-control'
                type='text'
                onChange={(x) => {
                  setTemplateName(x.target.value);
                }}
                value={templateName}
              />
            </div>
            {!!userOrganization && (
              <div className='d-flex gap-2 mt-2'>
                <Label className='form-label'>Share template with organization?</Label>
                <Input
                  name='shareTemplate'
                  className='form-control'
                  type='checkbox'
                  onChange={(x) => {
                    setShareTemplate(x.target.checked);
                  }}
                  checked={shareTemplate}
                />
              </div>
            )}
            <div className='text-center'>
              {isUploading ? (
                <Spinners />
              ) : (
                <Button
                  type='button'
                  color='primary'
                  className='btn mt-3'
                  onClick={handleFileUpload}
                >
                  Upload Document
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {error && (
        <div className='alert alert-danger mt-4' role='alert'>
          {error}
        </div>
      )}
    </Form>
  );
};

export default UploadTemplateDropZone;
