import { createSlice } from '@reduxjs/toolkit';

export interface FeedbackState {
  message: string;
  loading: boolean;
  error: string | null;
  thankYouMessage: boolean;
  popoverOpen: boolean;
  fabFocused: boolean;
}

const initialState: FeedbackState = {
  message: '',
  loading: false,
  error: '',
  thankYouMessage: false,
  popoverOpen: false,
  fabFocused: false,
};

const FeedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export default FeedbackSlice.reducer;
