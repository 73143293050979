import { getFirebaseBackend } from '../firebase';
import { parseHtmlToDocx } from './html_to_docx_parser';
import { loadDocx } from './lazyLoadLibraries';

const docx = loadDocx();

export const downloadFile = async (blob: Blob, filename: string) => {
  console.log('downloadFile', blob);
  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a hidden link and trigger a click to download the file
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};

export const downloadXLSX = async (workbook: any, filename: string) => {
  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  await downloadFile(blob, filename);
};

export const downloadPDFFromHtml = async (planId: string, filename: string) => {
  const firebaseHelper = getFirebaseBackend();
  await downloadFile(await firebaseHelper.getPlanSummaryPdf(planId), filename);
};

export const downloadDocxFromHtml = async (html: string, filename: string) => {
  // Generate the DOCX file as a blob
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');

  const externalStyles = await fetch(`${process.env.PUBLIC_URL}/docx-styles.xml`).then((res) =>
    res.text(),
  );

  const doc = new (await docx)!.Document({
    sections: [
      {
        properties: {},
        children: parseHtmlToDocx(htmlDoc.body),
      },
    ],
    externalStyles,
  });

  const blob = await (await docx)!.Packer.toBlob(doc);

  await downloadFile(blob, filename);
};
