import { combineReducers } from 'redux';

// Front
import ForgotPasswordReducer from './auth/forgetpwd/reducer';
import LoginReducer from './auth/login/reducer';
import ProfileReducer from './auth/profile/reducer';
import AccountReducer from './auth/register/reducer';
import FeedbackReducer from './feedback/reducer';
import LayoutReducer from './layouts/reducer';
import PlanComparisonReducer from './plan/planComparisonReducer';
import PlanDetailsReducer from './plan/planDetailsReducer';
import PlanListReducer from './plan/planListReducer';
import TemplateReducer from './template/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgotPasswordReducer,
  Account: AccountReducer,
  Feedback: FeedbackReducer,
  PlanList: PlanListReducer,
  PlanDetails: PlanDetailsReducer,
  PlanComparison: PlanComparisonReducer,
  Templates: TemplateReducer,
});

export default rootReducer;
