import OtpInput from 'react-otp-input';

const OtpInputCode = ({ otp, setOtp }: any) => {
  return (
    <OtpInput
      shouldAutoFocus={true}
      inputStyle={'inputStyle'}
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
  );
};

export default OtpInputCode;
