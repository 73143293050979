import { AppDispatch } from 'index';
import { PlanComparisonState } from 'slices/plan/planComparisonReducer';

export const setLayout = (
  dispatch: AppDispatch,
  payload: Partial<PlanComparisonState['layout']>,
) => {
  dispatch({
    type: 'planComparison/setLayout',
    payload,
  });
};
