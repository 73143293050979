import { MailItem, PDAICollections, ProvisionAnswer } from '@pdai/shared';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  getMultiFactorResolver,
  GoogleAuthProvider,
  multiFactor,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TotpMultiFactorGenerator,
  updateProfile,
} from 'firebase/auth';

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage';
import { apiError, loginSuccess } from 'slices/auth/login/reducer';
import { profileError } from 'slices/auth/profile/reducer';

export interface Plan {
  id?: string;
  userId: string;
  userEmail?: string;
  documentName?: string;
  fileName: string;
  fileUri?: string;
  downloadUrl?: string;
  answers?: ProvisionAnswer[];
  prompt?: string;
  summary?: string;
  status: string;
  errorMessage?: string;
  updatedAt?: Timestamp;
  createdAt?: Timestamp;
}

export interface ExportTemplate {
  id?: string;
  userId: string;
  organizationId?: string;
  templateName?: string;
  fileName: string;
  fileUri?: string;
  downloadUrl?: string;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

class FirebaseHelper {
  readonly storage;
  readonly auth;
  readonly db;
  readonly functions;
  readonly planFilesCollection;
  readonly firebaseConfig;

  private readonly SESSION_EXPIRATION_TIME = 2 * 60 * 60 * 1000; // 2 hours

  constructor(firebaseConfig: any) {
    if (firebaseConfig) {
      // Initialize Firebase
      this.firebaseConfig = firebaseConfig;
      const app = initializeApp(firebaseConfig);
      this.auth = getAuth(app);

      onAuthStateChanged(this.auth, async (user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
          // Set initial expiration time and start timer
          this.resetSessionExpirationTimer();
          const token = await user.getIdTokenResult();
          console.log(token.claims);
          localStorage.setItem('profileUser', token.claims?.admin ? 'admin' : 'user');
          localStorage.setItem('organizationId', token.claims?.organizationId + '');
        } else {
          console.log('user logged out.');
          localStorage.removeItem('authUser');
          localStorage.removeItem('profileUser');
          localStorage.removeItem('organizationId');
          localStorage.removeItem('expirationTime');
        }
      });

      this.storage = getStorage(app);
      this.functions = getFunctions(app);

      // Initialize Cloud Firestore and get a reference to the service
      this.db = getFirestore(app);
      this.planFilesCollection = collection(this.db, 'planFiles');
    }
  }

  isAdminUser = () => {
    return localStorage.getItem('profileUser') == 'admin';
  };

  resetSessionExpirationTimer = (checkExpiration: boolean = false) => {
    if (checkExpiration) {
      const expirationTime = parseInt(localStorage.getItem('expirationTime') || '0');

      if (expirationTime > 0 && Date.now() > expirationTime) {
        console.log('logging out');
        this.logout().catch((error) => {
          console.error('Error signing out:', error);
        });
        window.location.href = window.location.protocol + '//' + window.location.host + '/login';
        return;
      }
    }

    const newExpirationTime = Date.now() + this.SESSION_EXPIRATION_TIME;
    localStorage.setItem('expirationTime', newExpirationTime.toString());
  };

  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  // Basic document analysis
  async editProfileAPI(userName: string): Promise<any> {
    try {
      const currentUser = this.auth.currentUser;
      if (currentUser) {
        await updateProfile(currentUser, { displayName: userName });
        return userName;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error editing profile username:', error);
      throw error;
    }
  }

  loginUser = async (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  getTOTPSecretForEnrollment = async (dispatch) => {
    try {
      const multiFactorSession = await multiFactor(this.auth.currentUser).getSession();
      const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      const qrCode = totpSecret.generateQrCodeUrl(this.auth.currentUser.email, 'PlanPort');
      return { totpSecret, qrCode };
    } catch (error: any) {
      if (error.code == 'auth/requires-recent-login') {
        dispatch(
          profileError(
            `Session refresh required. Please log out and log back in to configure MFA.`,
          ),
        );
        return;
      }
      throw error;
    }
  };

  enrollUserOnMfa = async (totpSecret, verificationCode) => {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
      totpSecret,
      verificationCode,
    );
    await multiFactor(this.auth.currentUser).enroll(
      multiFactorAssertion,
      'PlanPort MFA Authenticator',
    );
  };

  authenticateUserOnMfa = async (
    totpAuthenticationCode,
    authenticationError,
    dispatch,
    history,
  ) => {
    const mfaResolver = getMultiFactorResolver(this.auth, authenticationError);

    const enrolledFactor = mfaResolver.hints.filter(
      (info) => info.displayName == 'PlanPort MFA Authenticator',
    )[0];

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      enrolledFactor.uid,
      totpAuthenticationCode,
    );

    try {
      const userCredential = await mfaResolver.resolveSignIn(multiFactorAssertion);
      sessionStorage.setItem('authUser', JSON.stringify(userCredential));
      dispatch(loginSuccess(userCredential));
      history('/plans');
    } catch (error) {
      dispatch(
        apiError(
          new Error(
            'Incorrect verification code. Please enter the correct code from your authenticator app.',
          ),
        ),
      );
    }
  };

  disableUserMfa = async (dispatch) => {
    try {
      const enrolledFactor = multiFactor(this.auth.currentUser).enrolledFactors[0];
      await multiFactor(this.auth.currentUser).unenroll(enrolledFactor);
    } catch (error: any) {
      if (error.code == 'auth/requires-recent-login') {
        dispatch(
          profileError(
            `Session refresh required. Please log out and log back in to configure MFA.`,
          ),
        );
        return;
      }
      throw error;
    }
  };

  getUserMFAConfig = () => {
    const enrolledFactors = multiFactor(this.auth.currentUser).enrolledFactors;
    if (enrolledFactors.length > 0) {
      return enrolledFactors[0];
    }
    return null;
  };

  forgetPassword = (email: any) => {
    return new Promise((resolve, reject) => {
      console.log(
        'forgetPassword',
        window.location.protocol + '//' + window.location.host + '/login',
      );
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + '//' + window.location.host + '/login',
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          console.log('error:', error);
          reject(this._handleError(error));
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(this._handleError(error));
        });
    });
  };

  socialLoginUser = async (type: any) => {
    let provider: any;
    if (type === 'google') {
      provider = new GoogleAuthProvider();
    } else if (type === 'facebook') {
      provider = new FacebookAuthProvider();
    }
    try {
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  // Upload Plan File to Firebase
  async uploadPlanToStorage(file: File) {
    try {
      const newFile: Plan = {
        userId: this.auth.currentUser.uid,
        fileName: file.name,
        status: 'uploading',
        createdAt: serverTimestamp() as any,
        updatedAt: serverTimestamp() as any,
      };

      const docRef = await addDoc(collection(this.db, 'adoptionAgreements'), newFile);

      const metadata = {
        contentType: 'application/pdf',
        customMetadata: {
          documentId: docRef.id,
          fileType: 'planFile',
        },
      };

      // planFiles/USERID/DOCID/PDF_FILE.
      const filePath = `planFiles/${this.auth.currentUser.uid}/${docRef.id}/${file.name}`;
      console.log('filePath', filePath);
      const storageRef = ref(this.storage, filePath);
      await uploadBytes(storageRef, file, metadata);

      return docRef;
    } catch (error) {
      console.log(error);
    }
  }

  // Get template file
  async getTemplateFile(templateId: string) {
    try {
      const docRef = doc(this.db, 'exportTemplates', templateId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return data;
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Get templates for user
  async getUserExportTemplates() {
    try {
      const userId = this.auth.currentUser.uid;
      const querySnapshot = await getDocs(
        query(collection(this.db, 'exportTemplates'), where('userId', '==', userId)),
      );

      const templates = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return templates;
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  // Get templates for organization
  async getOrgExportTemplates() {
    try {
      const userId = this.auth.currentUser.uid;
      const orgId = this.getCurrentOrganization();
      console.log('orgId', orgId);

      if (!orgId) {
        return [];
      }

      const querySnapshot = await getDocs(
        query(
          collection(this.db, 'exportTemplates'),
          where('userId', '!=', userId),
          where('organizationId', '==', orgId),
        ),
      );

      const templates =
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) || [];

      return templates;
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  // Upload Template File to Firebase
  async uploadTemplateToStorage(file: File, templateName: string, shareTemplate: boolean) {
    try {
      const organizationId = this.getCurrentOrganization();
      const newFile: ExportTemplate =
        !!organizationId && shareTemplate
          ? {
              userId: this.auth.currentUser.uid,
              organizationId,
              fileName: file.name,
              templateName,
              createdAt: serverTimestamp() as any,
              updatedAt: serverTimestamp() as any,
            }
          : {
              userId: this.auth.currentUser.uid,
              fileName: file.name,
              templateName,
              createdAt: serverTimestamp() as any,
              updatedAt: serverTimestamp() as any,
            };

      const docRef = await addDoc(collection(this.db, 'exportTemplates'), newFile);

      const metadata = {
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        customMetadata: {
          documentId: docRef.id,
          fileType: 'exportTemplate',
        },
      };

      // exportTemplates/USERID/DOCID/EXCEL_FILE.
      const filePath = `exportTemplates/${this.auth.currentUser.uid}/${docRef.id}/${file.name}`;
      const storageRef = ref(this.storage, filePath);
      await uploadBytes(storageRef, file, metadata);

      // Update fileUri on the document
      await updateDoc(docRef, {
        fileUri: 'gs://' + this.firebaseConfig.storageBucket + '/' + filePath,
      });
      return docRef;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async deleteTemplate(id: string): Promise<void> {
    try {
      const docRef = doc(this.db, 'exportTemplates', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        if (data.fileUri) {
          const storageRef = ref(this.storage, data.fileUri);
          await deleteObject(storageRef);
        }

        await deleteDoc(docRef);
      } else {
        console.warn('Attempted to delete unexisting template:', id);
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      throw error;
    }
  }

  async editTemplate(
    id: string,
    templateName: string,
    shareTemplate: boolean,
  ): Promise<Partial<ExportTemplate>> {
    const organizationId = shareTemplate ? this.getCurrentOrganization() || '' : '';
    try {
      const docRef = doc(this.db, 'exportTemplates', id);
      await updateDoc(docRef, { templateName, organizationId });
      return { id, templateName, organizationId };
    } catch (error) {
      console.error('Error editing template:', error);
      throw error;
    }
  }

  async updatePlan(id: string, updatedData: Partial<Plan>): Promise<Partial<Plan>> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'adoptionAgreements', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plans:', error);
      throw error;
    }
  }

  async updateUser(id: string, updatedData: any): Promise<any> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'users', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating users:', error);
      throw error;
    }
  }

  async updatePlanWithProvisionAnswer(
    newProvisionAnswers: ProvisionAnswer[],
    planId: string,
  ): Promise<Partial<Plan>> {
    try {
      console.log('newProvisionAnswers', newProvisionAnswers);
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        // Assuming there's only one matching document
        const docRef = doc(this.db, 'adoptionAgreements', planId);
        const planToBeUpdated = await getDoc(docRef);

        const updatedData = {
          answers: planToBeUpdated.data()!.answers.map((oldAnswer: any) => {
            const foundProvision = newProvisionAnswers.find(
              (newProvision) => oldAnswer.provisionName === newProvision.provisionName,
            );
            return foundProvision || oldAnswer;
          }),
          updatedAt: serverTimestamp() as any,
        };

        await updateDoc(docRef, updatedData);

        return updatedData;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plan with provision answer:', error);
      throw error;
    }
  }

  async getUserActivity(): Promise<
    {
      user: {
        uid: string;
        email: string;
        displayName: string;
        organizationId: string | null;
        provider: any;
        metadata: any;
        numberOfAllowedDocuments: string;
        isAdmin: boolean;
      };
      logins: {
        userId: string;
        email: string;
        timestamp: any;
        metadata: any;
      }[];
      plans: Plan[];
    }[]
  > {
    try {
      const getUserActivityFunction = httpsCallable<
        any,
        {
          user: {
            uid: string;
            email: string;
            displayName: string;
            organizationId: string | null;
            provider: any;
            metadata: any;
            numberOfAllowedDocuments: string;
            isAdmin: boolean;
          };
          logins: {
            userId: string;
            email: string;
            timestamp: any;
            metadata: any;
          }[];
          plans: Plan[];
        }[]
      >(this.functions, 'getUserActivity');

      const result = await getUserActivityFunction();
      return result.data;
    } catch (error) {
      console.error('Error processing file:', error);
      throw error;
    }
  }

  async getOrganizationList(): Promise<
    {
      organizationId: string;
      organizationName: string;
      numberOfAllowedDocuments: number;
    }[]
  > {
    try {
      const organizationsSnapshot = await getDocs(collection(this.db, 'organizations'));
      const organizations = organizationsSnapshot.docs.map((doc) => ({
        organizationId: doc.id,
        organizationName: doc.data().name,
        numberOfAllowedDocuments: doc.data().numberOfAllowedDocuments,
      }));
      return organizations;
    } catch (error) {
      console.error('Error getting organizations:', error);
      throw error;
    }
  }

  async getOrganizationUsers(organizationId: string): Promise<
    {
      userId: string;
      email: string;
      numberOfAllowedDocuments: number;
    }[]
  > {
    try {
      // Get the organization document by ID
      const organizationDoc = await getDoc(
        doc(this.db, PDAICollections.Organizations, organizationId),
      );

      if (!organizationDoc.exists()) {
        throw new Error(`Organization with ID ${organizationId} not found.`);
      }

      // Get all users associated with the organization
      const usersSnapshot = await getDocs(
        query(collection(this.db, 'users'), where('organizationId', '==', organizationId)),
      );

      // Map the users to the desired format
      const users = usersSnapshot.docs.map((userDoc) => ({
        userId: userDoc.id,
        email: userDoc.data().email,
        numberOfAllowedDocuments: userDoc.data().settings.numberOfAllowedDocuments,
      }));

      return users;
    } catch (error) {
      console.error('Error getting users from organization:', error);
      throw error;
    }
  }

  async getOrganizationPlans(organizationId: string): Promise<Partial<Plan>[]> {
    try {
      const getOrganizationPlansCallable = httpsCallable<
        { organizationId: string },
        Partial<Plan>[]
      >(this.functions, 'getOrganizationPlans');

      return (await getOrganizationPlansCallable({ organizationId: organizationId })).data;
    } catch (error) {
      console.error('Error getting organization plans:', error);
      throw error;
    }
  }

  async setUserClaims(uid: string) {
    try {
      const setCustomClaimsFunction = httpsCallable(this.functions, 'setUserClaims');
      await setCustomClaimsFunction({ userId: uid });
      console.log(`Custom claims set for user: ${uid}`);
    } catch (error) {
      console.error('Error setting custom claims:', error);
      throw error;
    }
  }

  async createPlanSummary(planId: string) {
    try {
      const createPlanSummary = httpsCallable(this.functions, 'createPlanSummary');
      await createPlanSummary({ planId: planId });
    } catch (error) {
      console.error('Error setting custom claim:', error);
      throw error;
    }
  }

  async getPlanSummaryPdf(planId: string): Promise<Blob> {
    try {
      const getPlanSummaryPdf = httpsCallable<{ planId: string }, string>(
        this.functions,
        'getPlanSummaryPDF',
      );

      const base64Pdf = (await getPlanSummaryPdf({ planId: planId })).data; // Decode the Base64 string
      // Decode the Base64 string
      const byteCharacters = atob(base64Pdf);
      const byteArrays: Uint8Array[] = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }

      // Concatenate Uint8Arrays
      let totalLength = 0;
      for (const arr of byteArrays) {
        totalLength += arr.length;
      }

      const combinedArray = new Uint8Array(totalLength);
      let position = 0;
      for (const arr of byteArrays) {
        combinedArray.set(arr, position);
        position += arr.length;
      }

      const blob = new Blob([combinedArray], {
        type: 'application/pdf',
      });

      console.log('getPlanSummaryPDF', blob);

      return blob;
    } catch (error) {
      console.error('Error getting users from organization:', error);
      throw error;
    }
  }

  getCurrentUser() {
    const user = localStorage.getItem('authUser');

    if (!user) {
      throw new Error('User not authenticated');
    }
    return JSON.parse(user);
  }

  getCurrentOrganization(): string {
    return localStorage.getItem('organizationId')!;
  }

  _handleError(error: any) {
    console.log(error.code);

    if ((error.message as string).indexOf('Unauthorized email') > -1) {
      return `Oops! It looks like you don't have access to this application. If you believe this is an error, please contact support.`;
    }

    if (error.code === 'auth/popup-closed-by-user') {
      return;
    }

    return error;
  }

  // Submit Feedback
  async sendFeedback(feedback: string) {
    try {
      const userId = this.getCurrentUser().uid;
      const feedbackDoc = await addDoc(collection(this.db, PDAICollections.Mail), {
        to: 'info@plandataai.com',
        message: {
          subject: `Feedback/Issue from ${this.getCurrentUser().displayName} - (${
            this.getCurrentUser().email
          })`,
          text: `Check user feedback below:
          
${feedback}`,
        },
        meta: {
          createdAt: serverTimestamp() as any,
          userId: userId,
        },
      } as MailItem);
      return feedbackDoc;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export { FirebaseHelper };
