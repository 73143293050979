import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: '',
  error: '',
  loading: false,
  waitingMFA: false,
  mfaRequiredError: Error,
  isUserLogout: false,
  errorMsg: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
      state.waitingMFA = false;
    },
    loginSuccessMultiFactorRequired(state, action) {
      state.mfaRequiredError = action.payload;
      state.loading = true;
      state.waitingMFA = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
      state.waitingMFA = false;
    },
    resetLoginFlag(state) {
      // state.error = null;
      state.error = '';
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true;
    },
  },
});
export const {
  loginSuccess,
  loginSuccessMultiFactorRequired,
  apiError,
  resetLoginFlag,
  logoutUserSuccess,
} = loginSlice.actions;
export default loginSlice.reducer;
