import OtpInputCode from 'Components/Common/OtpInputCode';
import { AppDispatch } from 'index';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getFirebaseBackend } from '../../../firebase';

interface TotpAuthenticationModalProps {
  mfaRequiredError: Error;
  isOpen: boolean;
  toggle: () => void;
  history: any;
}

const TotpAuthenticationModal: React.FC<TotpAuthenticationModalProps> = ({
  isOpen,
  toggle,
  mfaRequiredError,
  history,
}) => {
  const [otpAuthenticationCode, setOtpAuthenticationCode] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authenticateUser = async () => {
      if (otpAuthenticationCode.length === 6) {
        const fireBaseBackend = getFirebaseBackend();
        fireBaseBackend.authenticateUserOnMfa(
          otpAuthenticationCode,
          mfaRequiredError,
          dispatch,
          history,
        );
      }
    };

    authenticateUser();
  }, [otpAuthenticationCode]);

  useEffect(() => {
    if (!isOpen) {
      setOtpAuthenticationCode('');
    }
  }, [isOpen]);

  return (
    <Modal style={{ maxWidth: '600px' }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <strong>2-Step Verification</strong>
      </ModalHeader>
      <ModalBody className='px-5'>
        <div className='py-4'>
          <strong>Enter the verification code from your authenticator app.</strong>
        </div>
        <OtpInputCode otp={otpAuthenticationCode} setOtp={setOtpAuthenticationCode} />
      </ModalBody>
      <ModalFooter className='px-4'>
        <Button className='px-3' color='gray-400' onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TotpAuthenticationModal;
