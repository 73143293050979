import Spinners from 'Components/Common/Spinner';
import { AppDispatch } from 'index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { fetchPlansByIds } from 'slices/plan/thunk';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import PlanComparisonBody from './sections/PlanComparisonBody';
import PlanComparisonHeader from './sections/PlanComparisonHeader';

const PlanComparison = () => {
  document.title = 'PlanPort - Plan Comparison';
  const { ids } = useParams();
  const planIds = ids!.split(',');

  const dispatch: AppDispatch = useDispatch();

  const { loading } = useSelector((state: any) => ({
    loading: state.PlanComparison.loading as boolean,
  }));

  useEffect(() => {
    dispatch(fetchPlansByIds(planIds));
  }, [dispatch]);

  return (
    <div className='page-content'>
      <React.Fragment>
        <Container fluid>
          <Breadcrumbs title='My Plans' breadcrumbItem='Plan Comparison' />
          <Row>
            <Col xs={12}>
              <Card>
                {loading ? (
                  <Spinners />
                ) : (
                  <>
                    <div className='p-3'>
                      <PlanComparisonHeader />
                    </div>
                    <div style={{ minHeight: '500px' }}>
                      <PlanComparisonBody />
                    </div>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default PlanComparison;
